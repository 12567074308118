@import "./_variable";

// common

.sub-heading {
  color: transparent;
  background: linear-gradient(to right, #7848f4, black);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 50px;
  font-family: $font-headings;
  line-height: $heading-line-height;
  letter-spacing: $heading-letter-spacing;
}

// bootcamp-hero-section
.bootcamp {
  .bootcamp-hero-section {
    h1 {
      color: transparent;
      background-color: $black;
      -webkit-background-clip: text;
      background-clip: text;
      font-size: 72px;
      font-weight: 700;
      font-family: $font-headings;

      span {
        background: linear-gradient(to right, #7848f4, black);
        -webkit-background-clip: text;
      }
    }

    h3 {
      font-size: 40px;
      color: $black;
      font-family: $font-headings;
    }

    p {
      font-size: 20px;
      font-family: $font-text;
      letter-spacing: -0.3px;
    }

    .card-section {
      .card-a {
        h5 {
          color: $black;
        }
      }

      .card-b {
        h5 {
          color: $black;
        }
      }
    }
  }

  .next-icon {
    z-index: -1;
    opacity: 0.1;
    right: 30%;
    top: 20%;
  }
}

.what-learn-section {
  h1 {
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
  }

  .card-section {
    p {
      font-family: $font-text;
      font-size: $text-size;
    }

    .icon-section {
      color: $primary-color;
      border: 1.6px solid #808000;
      border-radius: 50%;
      transition: all ease-in-out 0.5ms;
    }

    .icon-section-2 {
      border: 1.6px solid #ffc0cb;
      border-radius: 50%;
      transition: all ease-in-out 0.5ms;
    }
  }
}

// why-join-section

.why-join-section {
  h1 {
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
  }

  .why-join-card {
    .circle-icon {
      width: 60px;
      height: 60px;
      display: flex;
    }

    h5 {
      font-family: $font-headings;
      line-height: $heading-line-height;
      letter-spacing: $heading-letter-spacing;
    }

    p {
      font-family: $font-text;
      font-size: $text-size;
    }

    .icon {
      color: $light-voilet;
    }

    .border-border-secondary {
      background-color: #cccccc;
    }

    .border-border-primary {
      background-color: #e2d8ff;
      color: $light-voilet;
    }
  }
}

// who join section
.who-join-section {
  .who-join-card-section {
    border-radius: 10px;
    background-color: #f0ebff;

    color: $light-voilet;
  }

  .heading-4 {
    color: $light-voilet;
  }

  p {
    font-family: $font-text;
    font-size: $text-size;
  }

  .icon-section {
    color: $primary-color;
    font-size: 36px;
  }
}

//  enroll-section
.enroll-section {
  background-color: #e2d8ff;
  border-radius: 20px;

  h5 {
    color: $black;
    background-color: #ffffff;
    font-family: $font-headings;
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
  }

  h1 {
    font-size: 45px;
    color: $dark-voilet;
    font-family: $font-headings;
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
  }

  p {
    color: $dark-voilet;
    font-family: $font-text;
    font-size: $text-size;
  }
}

// success-story-section
.success-story-section {
  p {
    font-family: $font-text;
    font-size: $text-size;
  }

  .success-story-card {
    .icon-story {
      color: goldenrod;
    }

    .details-section {
      font-weight: 400;
      font-family: $font-text;
      font-size: $text-size;
    }
  }

  .card-body {
    height: 300px;
  }

  @media (min-width: 846px) {
    .card-body {
      height: 350px;
    }
  }
}

// get-start-section

.get-start-section {
  background-color: $primary-color;

  h1 {
    font-size: 40px;
    background-color: white;
    -webkit-background-clip: text;
    color: transparent;
    font-family: $font-headings;
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
  }

  p {
    font-family: $font-text;
    font-size: $text-size;
    color: #ffffff;
  }
}

//  effective-fee-section
.effective-fee-section {
  .effective-fee-col-1 {
    background-color: $light-voilet;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    h1 {
      font-size: 40px;
      color: #ffffff;

      span {
        color: $black;
      }
    }

    p {
      color: #ffffff;
      font-family: $font-text;
      font-size: $text-size;
    }
  }

  .effective-fee-col-2 {
    .card-a {
      background-color: #e2d8ff;
      border-radius: 10px;
      width: 300px;

      h4 {
        font-family: $font-headings;
        line-height: $heading-line-height;
        letter-spacing: $heading-letter-spacing;
        font-weight: 500;
      }

      p {
        font-family: $font-text;
        font-size: $text-size;
      }
    }

    .card-b {
      background-color: #daefe0;
      border-radius: 10px;
      width: 300px;

      h4 {
        font-family: $font-headings;
        line-height: $heading-line-height;
        letter-spacing: $heading-letter-spacing;
        font-weight: 500;
      }

      p {
        font-family: $font-text;
        font-size: $text-size;
      }
    }
  }
}

.steps-section {
  h5 {
    font-family: $font-headings;
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
  }

  p {
    font-family: $font-text;
    font-size: $text-size;
  }
}

// effective section
.steps-section {
  .steps-border-section {
    border-radius: 50%;
    padding: 20px 30px;
    background-color: #daefe0;
  }
}

//  certificate section

.certificate-section {
  p {
    font-family: $font-text;
    font-size: $text-size;
  }

  .certificate-div {
    cursor: pointer;

    span {
      color: #ac92f9;
    }
  }

  .border-section {
    border: 1px solid #9011ff;
    border-radius: 10px;
    background-color: #e2d8ff;
  }

  .certificate-details-section-a {
    border-radius: 10px;
    background-color: $primary-color;
  }

  .certificate-details-section-b {
    background-color: white;

    .logo-section {
      width: 130px;
      height: auto;
    }

    .course-name-details {
      span {
        color: #9011ff;
      }
    }
  }
}

@media (max-width: 480px) {

  // common
  .sub-heading {
    font-size: 35px;
  }

  .bootcamp-hero-section {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    h1 {
      font-size: 40px !important;
    }

    h3 {
      font-size: 24px !important;
    }

    p {
      font-size: 16px;
    }
  }

  //  what learn section
  .what-learn-section {
    .card-section {
      h5 {
        font-size: 22px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  //  enroll-section
  .enroll-section {
    h5 {
      font-size: 22px;
    }

    h1 {
      font-size: 35px;
    }

    p {
      line-height: 18px;
    }
  }

  // why-join-section

  .why-join-section {
    .circle-icon {
      margin: 0 auto;
    }

    h5 {
      font-size: 22px;
    }

    p {
      font-size: 18px;
    }
  }

  // success-story-section
  .success-story-section {
    p {
      font-size: 18px;
    }

    h5 {
      font-size: 22px;
    }
  }

  // who join section
  .who-join-section {
    .who-join-card-section {
      font-size: 22px;
    }
  }

  //  effective fees

  .effective-fee-section {
    .effective-fee-col-1 {
      border-radius: 20px;

      h1 {
        font-size: 35px;
      }

      p {
        font-size: 18px;
      }
    }

    .effective-fee-col-2 {
      .card-a {
        width: 100%;
      }

      .card-b {
        width: 100%;
      }
    }
  }

  //  certificate section

  .certificate-section {
    p {
      font-size: 20px;
    }

    .certificate-div {
      cursor: pointer;

      span {
        color: #ac92f9;
      }
    }

    .border-section {
      border: 1px solid #9011ff;
      border-radius: 10px;
      background-color: #e2d8ff;
    }

    .certificate-details-section-a {
      border-radius: 10px;
      background-color: #9011ff;
    }

    .certificate-details-section-b {
      background-color: white;

      h3 {
        font-size: 20px;
      }

      p {
        font-size: 18px;
      }

      .authorized-section {
        width: 70px;

        h6 {
          font-size: 14px;
        }
      }

      .logo-section {
        width: 95px;
        height: auto;
      }
    }
  }

  .get-start-section {
    h1 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
    }
  }
}